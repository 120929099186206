import { ProposableChallenge } from "models/Challenge";
import { SocketMessage } from "models/SocketMessage";
import SocketService from "./SocketService";

/**
 * Defines socket events emiters methods
 */
export default class SocketEmitter {
	/** 
	 * Sends CREATE_ROOM message to the server
	 * 
	 * @param {string} playerName The player name
	 * @param {number} categoryLevel The challenges category level
	 */
	static createRoom (playerName: string, categoryLevel: number) {
		SocketService.emit(SocketMessage.CREATE_ROOM, {
			playerName,
			categoryLevel
		});
	}

	/** 
	 * Sends JOIN_ROOM message to the server
	 * 
	 * @param {string} playerName The player name
	 * @param {string} roomCode The code of room to join
	 */
	static joinRoom (playerName: string, roomCode: string) {
		SocketService.emit(SocketMessage.JOIN_ROOM, {
			roomCode,
			playerName
		});
	}

	/** 
	 * Sends EXPULSE_PLAYER message to the server
	 * 
	 * @param {string} playerName The player name
	 */
	static expulsePlayer (playerName: string) {
		SocketService.emit(SocketMessage.EXPULSE_PLAYER, { playerName });
	}

	/**
	 * Sends LEAVE_PARTY message to the server
	 */
	static leaveParty () {
		SocketService.emit(SocketMessage.LEAVE_PARTY);
	}

	/** 
	 * Sends START_PARTY message to the server
	 */
	static startParty () {
		SocketService.emit(SocketMessage.START_PARTY);
	}

	/** 
	 * Sends FINISH_PARTY message to the server
	 */
	static finishParty () {
		SocketService.emit(SocketMessage.FINISH_PARTY);
	}

	/** 
	 * Sends COMPLETE_CHALLENGE message to the server
	 * 
	 * @param {string} roomCode The room code
	 * @param {string} challengeId The id of the challenge to complete
	 * @param {boolean} success A value indicating whether the challenge was successful
	 */
	static completeChallenge (roomCode: string, challengeId: string, success: boolean) {
		SocketService.emit(SocketMessage.COMPLETE_CHALLENGE, {
			roomCode,
			challengeId,
			success
		});
	}

	/** 
	 * Sends SEND_CUSTOM_CHALLENGES message to the server
	 * 
	 * @param {ProposableChallenge[]} challenges The custom challenges list to send
	 * @param {string} looserChallenge The challenge for the looser
	 * @param {string} captchaToken The captcha token
	 */
	static sendCustomChallenges (challenges: ProposableChallenge[], looserChallenge: string, captchaToken: string) {
		SocketService.emit(SocketMessage.SEND_CUSTOM_CHALLENGES, {
			challenges,
			looserChallenge,
			captchaToken
		});
	}

}