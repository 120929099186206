import { SocketMessage } from "models/SocketMessage";
import { io, Socket } from "socket.io-client";
import { SERVER_URL, APP_VERSION } from "@env";
import { GlobalSocketEvents } from "./GlobalSocketEvents";

/**
 * Defines the socket service
 */
export default class SocketService {
	private static socket: Socket;

	/**
	 * Connects to the server
	 */
	static connect () {
		if (!SocketService.socket) {
			SocketService.socket = io(SERVER_URL, {
				transports: ["websocket"],
				query: {
					appVersion: APP_VERSION
				}
			});

			GlobalSocketEvents.init();
		}

		if (SocketService.socket.disconnected) {
			SocketService.socket.connect();
		}
	}

	/**
	 * Disconnect from the server
	 */
	static disconnect () {
		SocketService.socket.disconnect();
	}

	/**
	 * Detemine a value indicating wether the socket is connected
	 * 
	 * @returns {boolean} True if the socket is connected, false otherwise
	 */
	static isConnected () {
		return SocketService.socket?.connected;
	}

	/**
	 * Subscribe listener to message
	 * 
	 * @param {SocketMessage} message The message to subscribe
	 * @param { (args: any[]) => void} action The handler to execute when the message is received
	 */
	static on (message: SocketMessage, action: (...args: Any[]) => void) {
		SocketService.socket.on(message, action);
	}

	/**
	 * Emit a socket message 
	 * 
	 * @param {SocketMessage} message The message to emit
	 * @param {unknown | undefined} data The data to send
	 */
	static emit (message: SocketMessage, data?: Any) {
		SocketService.socket.emit(message, data);
	}

	/**
	 * Unsubscribe from an event message
	 * 
	 * @param {SocketMessage} message The event message
	 * @param  {(args: any[]) => void} action The ation function to remove
	 */
	static off (message: SocketMessage, action: (...args: Any[]) => void) {
		if (SocketService.socket) {
			SocketService.socket.off(message, action);
		}
	}

	/**
	 * Unsubscribe from all events of a message
	 * 
	 * @param {SocketMessage} message The event message to unsubscribe from
	 */
	static removeAllListeners (message: SocketMessage) {
		if (SocketService.socket) {
			SocketService.socket.removeAllListeners(message);
		}
	}
}