import React, { useState, useEffect } from "react";
import { Alert, HStack, Text, VStack } from "native-base";
import NetInfo from "@react-native-community/netinfo";
import SocketReceiver from "services/socket/SocketReceiver";
import SocketService from "services/socket/SocketService";

function ConnectionErrorHandler () {
	const [isConnected, setIsConnected] = useState(true);

	useEffect(() => {
		setIsConnected(SocketService.isConnected());
		const unsubConnectionStateChange = NetInfo.addEventListener(state => {
			if (!SocketService.isConnected()) {
				setIsConnected(false);
				return;
			}

			if (state.isConnected === null) {
				setIsConnected(true);
			} else {
				setIsConnected(state.isConnected);
			}
		});

		const unsubOnConnect = SocketReceiver.onConnect(() => setIsConnected(true));
		const unsubOnConnectError = SocketReceiver.onConnectError(() => setIsConnected(false));

		return () => {
			unsubConnectionStateChange();
			unsubOnConnect();
			unsubOnConnectError();
		};
	}, []);

	return (
		!isConnected ? (
			<Alert w="full" status="error">
				<VStack space={2} flexShrink={1} w="100%">
					<HStack flexShrink={1} space={2} justifyContent="space-between">
						<HStack space={2} flexShrink={1}>
							<Alert.Icon mt="1" />
							<Text fontSize="md" color="coolGray.800">
                Impossible de se connecter au serveur !
							</Text>
						</HStack>
					</HStack>
				</VStack>
			</Alert>
		) : <></>
	);
}

export default ConnectionErrorHandler;