import { useEffect, useRef } from "react";
import { AppState, AppStateStatus } from "react-native";

interface IProps {
	onAppStateChange: (state: AppStateStatus, prevState: AppStateStatus, appInactiveSpentTime: number) => void;
}

export interface IProvidedValue {
	appStatus: AppStateStatus;
	prevAppStatus: AppStateStatus;
}

export function useAppState ({ onAppStateChange }: IProps) {
	const appStatusRef = useRef(AppState.currentState);
	const prevAppStatusRef = useRef(AppState.currentState);
	const lastInactiveAppDateRef = useRef<number>(0);

	useEffect(() => {
		const unsubStateChange = AppState.addEventListener("change", handleAppStateChange);
		return () => unsubStateChange.remove();
	}, []);

	const handleAppStateChange = (state: AppStateStatus) => {
		let appInactiveTime = 0;

		if (state === "active") {
			if (prevAppStatusRef.current !== "active") {
				appInactiveTime = Date.now() - lastInactiveAppDateRef.current;
			}
		} else {
			lastInactiveAppDateRef.current = Date.now();
		}

		onAppStateChange(state, prevAppStatusRef.current, appInactiveTime);

		appStatusRef.current = state;
		prevAppStatusRef.current = state;
	};

	const value: IProvidedValue = {
		appStatus: appStatusRef.current,
		prevAppStatus: prevAppStatusRef.current
	};

	return value;
}