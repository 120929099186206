export enum AlertType {
	Info = "Info",
	Success = "Success",
	Warning = "Warning",
	Error = "Error",
}

export type AlertData = {
	message: string,
	type: AlertType
}

export type PopupAlertData = {
	title: string,
	message: string
}