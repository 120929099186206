import React, { ReactNode, ReactElement } from "react";
import { Button, Divider, HStack, ScrollView, Stack, Text, useToken, View, VStack } from "native-base";
import { ColorType } from "native-base/lib/typescript/components/types";
import { Utils } from "utils/utils";

interface IProps {
	title: string;
	children: ReactNode;
	widgets?: ReactNode[];
	buttons?: ReactElement<typeof Button>[];
	verticalButtons?: boolean;
	color?: ColorType;
}

function Content ({ title, children, widgets, buttons, verticalButtons, color = "bg.500" }: IProps) {
	const [colorToken] = useToken("colors", [color as Any]);
	const containerColor = Utils.lightenDarkenColor(colorToken, 20) as string;

	return (
		<View flex={1} style={{ width: "85%" }}>
			<VStack
				justifyContent="center"
				maxH="full"
				borderRadius={50}
				overflow="hidden"
				style={{ backgroundColor: containerColor }}
			>
				<VStack
					alignItems="center"
					space={2}
					bgColor="bg.500"
					p={5}
					borderBottomRadius={20}
					backgroundColor={color}
				>
					<Text fontSize="2xl" textAlign="center" bold>{title}</Text>
					{widgets && widgets.length > 0 && <Divider />}
					<HStack alignContent="center" space={2}>
						{widgets?.map(w => w)}
					</HStack>
				</VStack>
				<ScrollView>
					<VStack justifyContent="center" space={2} p={5}>
						{children}
					</VStack>
				</ScrollView>
				<View bgColor="bg.500" p={5} borderTopRadius={20} backgroundColor={color}>
					<Stack flexDirection={verticalButtons ? "column" : "row"} justifyContent="space-around">
						{buttons?.map(b => b)}
					</Stack>
				</View>
			</VStack>
		</View>
	);
}

export default Content;