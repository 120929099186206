import { RemarkCard } from "components/Remark/RemarkCard";
import RemarksGroup from "components/Remark/RemarksGroup";
import { ChallengeType } from "models/Challenge";
import React from "react";

function ChlgeTypesExplanation () {
	return (
		<RemarksGroup>
			<RemarkCard title={ChallengeType.Solo}>
				{"Défi à réaliser seul. Le joueur gagnera / perdra un point."}
			</RemarkCard>
			<RemarkCard title={ChallengeType.Team}>
				{"Défi à réaliser en équipe. Chaque membre de l'équipe gagnera / perdra un point."}
			</RemarkCard>
			<RemarkCard title={ChallengeType.VS}>
				{"Défi à réaliser contre un autre joueur. Le joueur qui réussit le défi gagnera un point, et le perdant en perdra."}
			</RemarkCard>
			<RemarkCard title={ChallengeType.Thief}>
				{"Défi à réaliser seul. Le défi consiste à faire quelque chose à l'insu d'un autre joueur. En cas de succès, le joueur volera un point à l'autre."}
			</RemarkCard>
		</RemarksGroup>
	);
}

export default ChlgeTypesExplanation;