/* eslint-disable @typescript-eslint/no-var-requires */
import { Vibration } from "react-native";
import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from "expo-av";
import LocalStorage from "./LocalStorage";

/**
 * Defines the sound player
 */
export default class SoundPlayer {
	private static alertSound: Audio.Sound;

	/**
	 * Plays a sound
	 */
	static async playAlertSound () {
		const soundEnabled = await LocalStorage.getEnableSound();
		if (soundEnabled) {
			Audio.setAudioModeAsync({
				allowsRecordingIOS: false,
				staysActiveInBackground: true,
				interruptionModeIOS: InterruptionModeIOS.DuckOthers,
				playsInSilentModeIOS: true,
				shouldDuckAndroid: true,
				interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
				playThroughEarpieceAndroid: false
			});
			SoundPlayer.alertSound = (await Audio.Sound.createAsync(require("../assets/sounds/popup_alert.wav"))).sound;
			SoundPlayer.alertSound.playAsync();
		}

		const vibrationEnabled = await LocalStorage.getEnableVibration();
		if (vibrationEnabled) {
			Vibration.vibrate(500);
		}
	}
}