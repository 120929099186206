import React, { useState, useEffect } from "react";
import Popup from "components/Popup/Popup";
import { ChallengeType, CustomChallenge, ProposableChallenge } from "models/Challenge";
import SocketEmitter from "services/socket/SocketEmitter";
import CChallengeList from "./CChallengeList";
import AddCChallengeForm from "./AddCChallengeForm";
import LooserChallengeForm from "./LooserChallengeForm";
import WithRecaptchaProvider from "components/Recaptcha/WithRecaptchaProvider";
import Recaptcha from "components/Recaptcha/Recaptcha";
import LocalStorage from "services/LocalStorage";
import { useMyToast } from "hooks/MyToast";
import SavedCustomChallenges from "./SavedCustomChallenges";

interface IProps {
	isOpen: boolean;
	onSent: () => void;
}

function CustomChallengeWrapper ({ isOpen, onSent }: IProps) {
	const [challenges, setChallenges] = useState<ProposableChallenge[]>([]);
	const [looserChallenge, setLooserChallenge] = useState("");
	const [view, setView] = useState<"list" | "addForm" | "importsaved" | "looserClgeForm">("addForm");
	const [duoChlgeEntered, setDuoChlgeEntered] = useState(false);
	const [initialProposeChlge, setInitialProposeChlge] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const toast = useMyToast();

	const sufficientChlge = challenges.length >= 4;

	useEffect(() => {
		setDuoChlgeEntered(
			challenges.some(c => c.challenge.type === ChallengeType.Team || c.challenge.type === ChallengeType.VS)
		);
	}, [challenges]);

	const handleShowCChallengesList = () => {
		setView("list");
	};

	const handleShowAddChallengeForm = () => {
		setView("addForm");
	};

	const handleShowImportView = () => {
		setView("importsaved");
	};

	const handleShowLooserChallengeForm = () => {
		setView("looserClgeForm");
	};

	const handleAddCChallenge = (cchallenge: CustomChallenge, proposeChlge: boolean) => {
		setInitialProposeChlge(proposeChlge);
		if (!challenges.some(c => c.challenge.description === cchallenge.description)) {
			setChallenges(value => [
				...value,
				{
					propose: proposeChlge,
					challenge: cchallenge
				}
			]);
			handleShowCChallengesList();
		}
	};

	const handleImportSavedChallenges = (challenges: CustomChallenge[]) => {
		for (const c of challenges) {
			handleAddCChallenge(c, false);
		}

		handleShowCChallengesList();
	};

	const handleRemoveCChallenge = (cchallenge: CustomChallenge) => {
		setChallenges(value => value.filter(c => c.challenge.description !== cchallenge.description));
	};

	const handleSaveCustomChallenges = () => {
		LocalStorage.setSavedCustomChallenges(challenges.map(c => c.challenge), []);
		toast.show("Success", "Vos défis ont bien été sauvegarder dans vos favoris!");
	};

	const handleLooserChallengeChange = (value: string) => {
		setLooserChallenge(value);
		setIsLoading(true);
	};

	const submitChallenges = (token: string) => {
		SocketEmitter.sendCustomChallenges(challenges, looserChallenge, token);
		setIsLoading(false);
		handleShowCChallengesList();
		onSent();
	};

	const getContent = () => {
		switch (view) {

			case "addForm":
				return (
					<AddCChallengeForm
						proposeChallenge={initialProposeChlge}
						onAdd={handleAddCChallenge}
						onCancel={handleShowCChallengesList}
					/>
				);

			case "importsaved":
				return (
					<SavedCustomChallenges
						onImport={handleImportSavedChallenges}
						onCancel={handleShowCChallengesList}
					/>
				);

			case "looserClgeForm":
				return (
					<LooserChallengeForm onValidate={handleLooserChallengeChange} />
				);

			default:
				return (
					<CChallengeList
						challenges={challenges.map(c => c.challenge)}
						validateBtnText={sufficientChlge ? "Valider" : `${4 - challenges.length} défis manquant(s) !`}
						isValidateBtnEnabled={!sufficientChlge || !duoChlgeEntered}
						showDuoChlgeError={sufficientChlge && !duoChlgeEntered}
						onItemRemove={handleRemoveCChallenge}
						onShowAddChallengeForm={handleShowAddChallengeForm}
						onShowImportView={handleShowImportView}
						onSaveCustomChallenges={handleSaveCustomChallenges}
						onShowLooserChallengeForm={handleShowLooserChallengeForm}
					/>
				);
		}
	};

	return (
		<WithRecaptchaProvider>
			<React.Fragment>
				<Popup isOpen={isOpen}>
					{getContent()}
				</Popup>
				{isLoading && <Recaptcha onReceiveToken={submitChallenges} />}
			</React.Fragment>
		</WithRecaptchaProvider>
	);
}

export default CustomChallengeWrapper;