import React, { useState } from "react";
import { Button, TextArea } from "native-base";
import PopupContent from "components/Popup/PopupContent";

interface IProps {
	onValidate: (challenge: string) => void
}

function LooserChallengeForm ({ onValidate }: IProps) {
	const [looserChallenge, setLooserChallenge] = useState("");

	const handlevalidate = () => {
		onValidate(looserChallenge);
	};

	return (
		<PopupContent
			title="Créer un gage"
			bottomButton={
				<Button colorScheme="green" isDisabled={!looserChallenge} onPress={handlevalidate}>
							valider
				</Button>
			}
		>
			<TextArea
				autoCompleteType="string"
				placeholder="Proposes un gage pour le perdant..."
				onChangeText={setLooserChallenge}
			/>
		</PopupContent>
	);
}

export default LooserChallengeForm;