import React from "react";
import { Modal } from "native-base";

interface IProps {
	isOpen: boolean;
	children: React.ReactNode;
	zIndex?: number
	onClose?: () => void;
}

function Popup ({ isOpen, children, zIndex, onClose }: IProps) {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			zIndex={zIndex}
			animationPreset="fade"
			_backdrop={{ opacity: 0.4, }}
			useRNModal
		>
			<Modal.Content w="85%" bg="rgb(0,0,0,0)">
				{children}
			</Modal.Content>
		</Modal>
	);
}

export default Popup;