import { ChallengeType } from "models/Challenge";
import { CommonResponse } from "models/requests/CommonRequest";
import {API_URL} from "@env";

/**
 * Defines proposal API service
 */
export class ProposalService {
	/** 
	 * Send a proposal to the server to be saved
	 *
	 * @param {string} description The description of the challenge
	 * @param {ChallengeType} type The type of the challenge
	 * @param {string[]} categories The categories of the challenge
	 * @param {string} email The email of the user 
	 * @param {string} captchaToken The captcha token
	 * @returns {Promise<CommonResponse<boolean>>} ok response
	 */
	static async sendProposal (description: string, type: ChallengeType, categories: string[],
		email: string, captchaToken: string) {
		const response = await fetch(`${API_URL}/proposal`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Accept": "*/*; charset=utf-8",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				description,
				type,
				categories,
				email,
				captchaToken
			})
		});

		return await response.json() as CommonResponse<boolean>;
	}
}