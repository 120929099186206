import React from "react";
import { Box, useToast } from "native-base";
import { AlertType } from "models/sockets/AlertData";

export interface MyToastType {
	show: (type: keyof typeof bgColors, message: string) => void
}

const bgColors: { [K in keyof typeof AlertType]: string } = {
	Info: "info.500",
	Success: "success.500",
	Warning: "warning.500",
	Error: "error.500",
};

export function useMyToast () {
	const toast = useToast();

	const show = (type: keyof typeof bgColors, message: string) => {
		toast.show({
			render: () => (
				<Box bgColor={bgColors[type]} px={2} py={1} mx={4} borderRadius={5}>
					{message}
				</Box>
			)
		});
	};

	return { show };
}