import React, { useState } from "react";
import { Input, Text, Button, VStack, TextArea, HStack, IconButton, Icon, Divider } from "native-base";
import CategoriesExplanation from "components/Rules/CategoriesExplanation";
import { ChallengeType } from "models/Challenge";
import ChlgeTypesExplanation from "components/Rules/ChlgeTypesExplanation";
import Checkbox from "components/Inputs/Checkbox";
import Stagger, { StaggerRefType } from "components/Stagger/Stagger";
import { FontAwesome5 } from "@expo/vector-icons";

interface IData {
	description: string;
	categories: string[];
	type: ChallengeType;
	email: string;
	addionalData?: Record<string, unknown>;
}

interface IStep {
	input: React.ReactNode;
	remark: React.ReactNode;
	isValid: () => boolean;
	enabledStep: boolean;
}

export interface IAdditionalStepInputProps {
	onDataChange?: (key: string, value: unknown) => void
}

export type IOption = {
	[K in keyof IData]?: {
		disabled?: boolean
	}
} & {
	additionalSteps?: (Omit<IStep, "input"> & {
		input: React.ReactElement<IAdditionalStepInputProps>
	})[]
}

interface IProvidedValue {
	data: IData;
	step: IStep & {
		stepIndex: number;
	};
	buttons: React.ReactElement<typeof Button>[];
	action: {
		gotoFirstStep: () => void;
		reset: () => void
	}
}

export function useChallengeEditor (onValidate: () => void, options?: IOption) {
	const [stepInx, setStepInx] = useState(0);

	const [description, setDescription] = useState("");
	const [categories, setCategories] = useState<string[]>([]);
	const [type, setType] = useState(ChallengeType.Solo);
	const [email, setEmail] = useState("");
	const [additionalData, setAdditionalData] = useState<Record<string, unknown>>({});

	let staggerRef: StaggerRefType | null = null;

	const handleAdditionalDataChange = (key: string, value: unknown) => {
		setAdditionalData(data => ({
			...data,
			[key]: value
		}));
	};

	const handleDescriptionChange = (value: string) => {
		setDescription(value);
	};

	const toggleCategory = (category: string, add: boolean) => {
		if (categories) {
			const newCategories = !add ? categories.filter(c => c !== category) : [...categories, category];
			setCategories(newCategories);
		}
	};

	const handleTypeChange = (value: ChallengeType) => {
		setType(value);
	};

	const handleEmailChange = (value: string) => {
		setEmail(value);
	};

	const prevState = () => {
		if (stepInx > 0) {
			setStepInx(stepInx => stepInx - 1);
		}
	};

	const nextState = () => {
		if (canGoToNextStep()) {
			setStepInx(stepInx => stepInx + 1);
		}
	};

	const canGoToNextStep = () => {
		return steps[stepInx].isValid();
	};

	const gotoFirstStep = () => {
		setStepInx(0);
	};

	const reset = () => {
		gotoFirstStep();
		setDescription("");
		setCategories([]);
		setType(ChallengeType.Solo);
	};

	const handleTextShortcut = (text: string) => {
		staggerRef?.close();
		setDescription(desc => `${desc} ${text} `);
	};

	const additionalSteps = options?.additionalSteps ?? [];
	const steps: IStep[] = [
		// Description
		{
			input: (
				<TextArea
					autoCompleteType="r"
					placeholder="Décris ton challenge"
					value={description}
					/* Highlight={/{rndPlayer\([0-9]+\)}|{currentPlayer}/g} */
					InputRightElement={
						<Stagger
							ref={ref => staggerRef = ref}
							animate={{
								translateX: -85
							}}
						>
							<HStack divider={<Divider />} bgColor="bg.500" px={2} py={1} borderRadius="full">
								<IconButton
									key="currentPlayer"
									borderRadius="full"
									icon={<Icon as={FontAwesome5}
										size={5}
										name="user-alt"
										color="text.50"
									/>}
									onPress={() => handleTextShortcut("{currentPlayer}")}
								/>
								<IconButton
									key="rndPlayer"
									borderRadius="full"
									icon={<Icon as={FontAwesome5}
										size={5}
										name="random"
										color="text.50"
									/>}
									onPress={() => handleTextShortcut("{rndPlayer(0)}")}
								/>
							</HStack>
						</Stagger>
					}
					onChangeText={handleDescriptionChange}
				/>
			),
			remark: (
				<VStack>
					<VStack space={4}>
						<HStack flexWrap="wrap" space={2}>
							<Icon as={FontAwesome5}
								size={5}
								name="user-alt"
								color="text.50"
							/>
							<Text highlight>{"{currentPlayer}"}</Text>
							<Text> =  joueur qui fait le défi.</Text>
						</HStack>
						<HStack flexWrap="wrap" space={2}>
							<Icon as={FontAwesome5}
								size={5}
								name="random"
								color="text.50"
							/>
							<Text highlight>{"{rndPlayer(0)}"}</Text>
							<Text> = un joueur aléatoire. Changes le nombre pour un autre joueur aléatoire.</Text>
						</HStack>
					</VStack>
					<Text mt={5} bold>Si tu écris :</Text>
					<Text>
						<Text color="orange.400">{"{currentPlayer}"}</Text> et <Text color="green.400">{"{rndPlayer(0)}"}</Text> faites une blague à <Text color="yellow.500">{"{rndPlayer(1)}"}</Text>
					</Text>
					<Text bold mt={5}>Cela produira :</Text>
					<Text>
						<Text color="orange.400">Jean</Text> et <Text color="green.400">Adam</Text> faites une blague à <Text color="yellow.500">Lina</Text>
					</Text>
				</VStack>
			),
			isValid: () => description.length > 0,
			enabledStep: !options?.description?.disabled
		},

		// Categories
		{
			input: (
				<>
					<Text fontSize="xl" textAlign="center">Choisis la ou les catégories</Text>
					<Checkbox
						label="Soft"
						checked={categories.includes("Soft")}
						onChange={(v: boolean) => toggleCategory("Soft", v)}
					/>
					<Checkbox
						label="Normal"
						checked={categories.includes("Normal")}
						onChange={(v: boolean) => toggleCategory("Normal", v)}
					/>
					<Checkbox
						label="Hard"
						checked={categories.includes("Hard")}
						onChange={(v: boolean) => toggleCategory("Hard", v)}
					/>
				</>
			),
			remark: (
				<CategoriesExplanation />
			),
			isValid: () => categories.length > 0,
			enabledStep: !options?.categories?.disabled
		},

		// Challenge type
		{
			input: (
				<>
					<Text fontSize="xl" textAlign="center">Choisis le type de défi</Text>
					<HStack justifyContent="space-around">
						<Checkbox
							label="Solo"
							checked={type === ChallengeType.Solo}
							onChange={() => handleTypeChange(ChallengeType.Solo)}
						/>
						<Checkbox
							label="Team"
							checked={type === ChallengeType.Team}
							onChange={() => handleTypeChange(ChallengeType.Team)}
						/>
					</HStack>
					<HStack justifyContent="space-around">
						<Checkbox
							label="VS"
							checked={type === ChallengeType.VS}
							onChange={() => handleTypeChange(ChallengeType.VS)}
						/>
						<Checkbox
							label="Thief"
							checked={type === ChallengeType.Thief}
							onChange={() => handleTypeChange(ChallengeType.Thief)}
						/>
					</HStack>
				</>
			),
			remark: (
				<ChlgeTypesExplanation />
			),
			isValid: () => type !== null && type !== undefined,
			enabledStep: !options?.type?.disabled
		},

		// Email
		{
			input: (
				<Input
					placeholder="Ton email... (optionnel)"
					value={email}
					onChangeText={handleEmailChange}
				/>
			),
			remark: (
				<VStack space={5}>
					<Text>Si tu veux savoir si ton défi a été accepté ou pas, tu peux saisir ton email.</Text>
					<Text>Il sera supprimer après validation du défi 😉</Text>
				</VStack>
			),
			isValid: () => true,
			enabledStep: !options?.email?.disabled
		},

		// Additional steps
		...additionalSteps.map(s => ({
			...s,
			input: React.cloneElement(s.input, {
				...s.input.props,
				onDataChange: (key: string, value: unknown) => {
					handleAdditionalDataChange(key, value);
					if (s.input.props.onDataChange) {
						s.input.props.onDataChange(key, value);
					}
				}
			})
		}))
	].filter(s => s.enabledStep);

	const buttons = [];
	if (stepInx > 0) {
		buttons.push(
			<Button
				key="1"
				color="#cfcfcf"
				onPress={prevState}
			>
                Retour
			</Button>
		);
	}

	if (stepInx < steps.length - 1) {
		buttons.push(
			<Button
				key="2"
				color="lightgreen"
				isDisabled={!canGoToNextStep()}
				onPress={nextState}
			>
                Suivant
			</Button>
		);
	} else {
		buttons.push(
			<Button
				key="3"
				color="lightgreen"
				isDisabled={!canGoToNextStep()}
				onPress={onValidate}
			>
                Valider
			</Button>
		);
	}

	const value: IProvidedValue = {
		data: {
			description,
			categories,
			type,
			email,
			addionalData: additionalData
		},
		step: {
			stepIndex: stepInx,
			...steps[stepInx]
		},
		buttons,
		action: {
			gotoFirstStep,
			reset
		}
	};

	return value;
}