import React, { useState } from "react";
import { Text, Button, VStack } from "native-base";
import Content from "components/Content/Content";
import Header from "components/Header/Header";
import ChlgeTypesExplanation from "components/Rules/ChlgeTypesExplanation";
import CategoriesExplanation from "components/Rules/CategoriesExplanation";
import Screen from "components/Screen/Screen";

function RulesScreen () {
	const [ruleInx, setRuleInx] = useState(0);

	const prevRule = () => {
		if (ruleInx > 0) {
			setRuleInx(ruleInx - 1);
		}
	};

	const nextRule = () => {
		if (ruleInx < rules.length - 1) {
			setRuleInx(ruleInx + 1);
		}
	};

	const rules: ({ title: string, element: React.ReactNode })[] = [
		{
			title: "Objectif",
			element: (
				<VStack space={5} px={2}>
					<Text>{"\u2022"} Réussir le plus de défis</Text>
					<Text>{"\u2022"} Ne pas être le dernier du classement</Text>
				</VStack>
			),
		},
		{
			title: "Catégories de défis",
			element: <CategoriesExplanation />,
		},
		{
			title: "Types de défis",
			element: <ChlgeTypesExplanation />,
		},
		{
			title: "Dernier du classement",
			element: <Text textAlign="center">
				À la fin de la soirée, le dernier du classement aura un gage à réaliser au profit des autres joueurs.
			</Text>,
		}
	];

	return (
		<Screen>
			<Header showBackButton />
			<Content
				title={rules[ruleInx].title}
				buttons={[
					<Button key="prev" isDisabled={ruleInx === 0} onPress={prevRule}>Précédant</Button>,
					<Button key="next" isDisabled={ruleInx >= rules.length - 1} onPress={nextRule}>Suivant</Button>
				]}
			>
				{rules[ruleInx].element}
			</Content>
		</Screen>
	);
}

export default RulesScreen;