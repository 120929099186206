import React, { useState, useEffect } from "react";
import { Button, HStack, Text, VStack } from "native-base";
import { IAdditionalStepInputProps, IOption, useChallengeEditor } from "hooks/ChallengeEditor";
import { CustomChallenge } from "models/Challenge";
import Popup from "components/Popup/Popup";
import PopupContent from "components/Popup/PopupContent";
import Checkbox from "components/Inputs/Checkbox";

interface IProps {
	proposeChallenge: boolean,
	onAdd: (cchallenge: CustomChallenge, proposeChlge: boolean) => void,
	onCancel: () => void,
}

function AddCChallengeForm ({ proposeChallenge, onAdd, onCancel }: IProps) {
	const [openRemark, setOpenRemark] = useState(false);

	const toggleRemarkPopup = () => {
		setOpenRemark(value => !value);
	};

	const handleAdd = () => {
		const cchallenge: CustomChallenge = {
			description: editor.data.description,
			type: editor.data.type,
		};

		const propseChlge = editor.data.addionalData?.submitChallenge as boolean ?? false;
		onAdd(cchallenge, propseChlge);
	};

	const editorOption: IOption = {
		categories: {
			disabled: true
		},
		email: {
			disabled: true
		},
		additionalSteps: [
			// Make a challenge proposition
			{
				input: <ChallengePropositionStep initialValue={proposeChallenge} />,
				remark: <Text>Les joueurs du monde entier pourront relever votre défi.</Text>,
				enabledStep: true,
				isValid: () => true
			}
		]
	};

	const editor = useChallengeEditor(handleAdd, editorOption);

	return (
		<PopupContent title="Créer un défi" showButton={false}>
			<VStack>
				{editor.step.input}
				<Button my={5} onPress={toggleRemarkPopup}>Remarques</Button>
				<HStack justifyContent="space-evenly" mt={5}>
					{
						editor.step.stepIndex === 0 && <Button onPress={onCancel}>Retour</Button>
					}
					{editor.buttons}
				</HStack>
			</VStack>
			<Popup isOpen={openRemark}>
				<PopupContent title="Remarques" onHide={toggleRemarkPopup}>
					{editor.step.remark}
				</PopupContent>
			</Popup>
		</PopupContent>
	);
}

interface IChallengPropositionProps extends IAdditionalStepInputProps {
	initialValue: boolean
}

function ChallengePropositionStep (props: IChallengPropositionProps) {
	const [submitChallenge, setSubmitChallenge] = useState(props.initialValue);

	useEffect(() => {
		handleSubmitChlgeChange(props.initialValue);
	}, []);

	const handleSubmitChlgeChange = (value: boolean) => {
		setSubmitChallenge(value);
		if (props.onDataChange) {
			props.onDataChange("submitChallenge", value);
		}
	};

	return (
		<VStack>
			<Text>Voulez-vous proposer ce défi au reste du monde ?</Text>
			<HStack justifyContent="space-evenly">
				<Checkbox label="Non" checked={!submitChallenge} onChange={() => handleSubmitChlgeChange(false)} />
				<Checkbox label="Oui" checked={submitChallenge} onChange={() => handleSubmitChlgeChange(true)} />
			</HStack>
		</VStack>
	);
}

export default AddCChallengeForm;