/**
 * Defines utility methods
 */
export class Utils {
	/** 
	 *  Lighten or darken a color
	 *
	 * @param {string} color - The color to lighten or darken
	 * @param {number} amt - The amount to lighten or darken the color (< 0 = darken, > 0 = lighten)
	 * @returns {string} The lighten or darken color
	 */
	static lightenDarkenColor (color: string, amt: number) {
		let usePound = false;

		if (color[0] === "#") {
			color = color.slice(1);
			usePound = true;
		}

		const num = parseInt(color, 16);

		let r = (num >> 16) + amt;

		if (r > 255) r = 255;
		else if (r < 0) r = 0;

		let b = ((num >> 8) & 0x00FF) + amt;

		if (b > 255) b = 255;
		else if (b < 0) b = 0;

		let g = (num & 0x0000FF) + amt;

		if (g > 255) g = 255;
		else if (g < 0) g = 0;

		return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

	}
}