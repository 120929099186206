import React, { useImperativeHandle, ForwardedRef } from "react";
import { Box, HStack, Icon, IconButton, Stagger as NBStagger, useDisclose } from "native-base";
import { IStaggerStyleProps } from "native-base/lib/typescript/components/composites/Transitions/Stagger";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Platform } from "expo-modules-core";

export interface StaggerRefType {
	close: () => void
}

interface IProps {
	children: React.ReactElement;
	initial?: IStaggerStyleProps;
	animate?: IStaggerStyleProps;
	exit?: IStaggerStyleProps;
}

const Stagger = React.forwardRef(function Stagger (props: IProps, ref: ForwardedRef<StaggerRefType>) {
	const { isOpen, onClose, onToggle } = useDisclose();

	const isWebPlatform = Platform.OS === "web";

	useImperativeHandle(ref, () => ({
		close
	}), []);

	const close = () => {
		onClose();
	};

	return (
		<Box position="relative">
			<Box alignItems="center" position={isWebPlatform ? "fixed" : "absolute"}>
				<NBStagger
					visible={isOpen}
					initial={{
						opacity: 0,
						scale: 0,
						...props.initial
					}}
					animate={{
						translateX: 85,
						scale: 1,
						opacity: 1,
						transition: {
							type: "spring",
							mass: 0.8,
							stagger: {
								offset: 30,
								reverse: true
							}
						},
						...props.animate
					}}
					exit={{
						translateX: 0,
						scale: 0.5,
						opacity: 0,
						transition: {
							duration: 100,
							stagger: {
								offset: 30,
								reverse: true
							}
						},
						...props.exit
					}}>
					<Box display={isWebPlatform && !isOpen ? "none" : "flex"}>
						{React.cloneElement(props.children, { onPress: onToggle })}
					</Box>
				</NBStagger>
			</Box>
			<HStack>
				<IconButton
					borderRadius="full"
					icon={
						<Icon
							as={MaterialCommunityIcons}
							size="lg"
							name="dots-horizontal"
							color="text.50"
						/>
					}
					onPress={onToggle}
				/>
			</HStack>
		</Box>
	);
});

export default Stagger;