import React, { useState, useEffect } from "react";
import { Animated } from "react-native";
import { Button, Text, View, ScrollView, VStack, useToken } from "native-base";
import Screen from "components/Screen/Screen";
import Header from "components/Header/Header";
import TextSeparator from "components/TextSeparator/TextSeparator";
import { Table, Row } from "react-native-table-component";
import { useParty } from "components/providers/PartyProvider";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StackParamList } from "components/Navigator/Navigator";
import { GameStatus } from "models/Room";

function EndGameScreen () {
	const [opacitySentence] = useState(new Animated.Value(0));
	const [opacityLooser] = useState(new Animated.Value(0));
	const [opacityChalleng] = useState(new Animated.Value(0));
	const [revealCountdown, setRevealCountdown] = useState(3);
	const [stepInx, setStepInx] = useState(0);

	const navigation = useNavigation<NavigationProp<StackParamList>>();
	const { party, clearParty } = useParty();

	const [textColor] = useToken("colors", ["text.50"]);

	let showLooser = false;
	const revealed = revealCountdown <= 0;

	useEffect(() => {
		if (party?.room?.status !== GameStatus.Finished) {
			navigation.navigate("Home");
		}
	}, [party, navigation]);

	useEffect(() => {
		animateText(opacitySentence, true);

		let revealInterval: NodeJS.Timeout;
		const countdownInterval = setInterval(() => {
			animateText(opacityLooser, !showLooser);
			showLooser = !showLooser;
		}, 1000);

		const revealTimeout = setTimeout(() => {
			revealInterval = setInterval(() => {
				setRevealCountdown(revealCountdown => {
					if (revealCountdown > 0) {
						if (revealCountdown - 1 <= 0) {
							clearInterval(countdownInterval);
							clearInterval(revealInterval);
						}
						const newValue = revealCountdown - 1;
						if (newValue === 0) {
							animateText(opacityChalleng, true);
						}
						return newValue;
					}
					return revealCountdown;
				});

			}, 2000);
		}, 1000);

		return () => {
			clearTimeout(revealTimeout);
			clearInterval(countdownInterval);
			clearInterval(revealInterval);
		};
	}, []);

	const txtAnime = (value: Animated.Value) => ({
		opacity: value,
	});

	const animateText = (state: Animated.Value, show: boolean) => {
		Animated.timing(state, {
			toValue: show ? 1 : 0,
			useNativeDriver: true,
		}).start();
	};

	const steps = [
		<>
			<Animated.Text
				style={[{
					margin: 0,
					textAlign: "center",
					fontSize: 24,
					fontWeight: "bold",
					color: textColor
				},
				txtAnime(opacitySentence)]}
			>
				Le grand perdant est ...
			</Animated.Text>
			<Animated.Text
				style={[{
					margin: 5,
					color: "red",
					textAlign: "center",
					fontSize: 50,
					fontWeight: "bold",
				},
				txtAnime(opacityLooser)]}
			>
				{!revealed ? revealCountdown : party?.looser}
			</Animated.Text>
			<Animated.View
				style={{
					width: "100%",
					paddingHorizontal: 20,
					opacity: opacityChalleng,
				}}
			>
				<TextSeparator text="Son gage" />
				<Text mt={5} textAlign="center" fontSize="xl" fontFamily="ComicSansMS">{party?.looserChallenge}</Text>
			</Animated.View>
		</>,
		<View key="rankContainer" w="full" maxH="80%">
			<View bgColor="bg.300" p={5}>
				<Text textAlign="center" fontSize="3xl" bold>Classement</Text>
			</View>
			<View bgColor="bg.300" p={5}>
				<Table>
					<Row
						data={["Rang", "Joueur", "points"]}
						textStyle={{
							color: textColor,
							fontSize: 18,
							fontWeight: "bold",
							textAlign: "center"
						}}
					/>
				</Table>
			</View>
			<ScrollView>
				<Table>
					{
						party?.room.players.map((p, inx) => (
							<Row
								key={inx}
								data={[p.rank, p.name, p.points]}
								style={{
									height: 50,
									backgroundColor: inx % 2 === 0 ? "#f2f2f2" : "#fff"
								}}
								textStyle={{
									color: "black",
									fontSize: 18,
									textAlign: "center",
								}}
							/>
						))
					}
				</Table>
			</ScrollView>
		</View>,
	];

	const nextStep = () => {
		if (stepInx < steps.length - 1) {
			setStepInx(stepInx => stepInx + 1);
		} else {
			clearParty();
			navigation.navigate("Home");
		}
	};

	return (
		<Screen>
			<Header><Text fontSize="3xl" bold>Fin de la soirée</Text></Header>
			<VStack justifyContent="space-evenly" alignItems="center" textAlign="center" w="full" flex={1}>
				{steps[stepInx]}
				<Button margin={10} color="white" disabled={!revealed} onPress={nextStep}>{
					stepInx < steps.length - 1 ? "Suivant" : "Revenir à l'accueil"
				}</Button>
			</VStack>
		</Screen>
	);
}

export default EndGameScreen;