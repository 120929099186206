import AsyncStorage from "@react-native-async-storage/async-storage";
import { CustomChallenge } from "models/Challenge";

/**
 * Defines the local storage service
 */
export default class LocalStorage {
	/**
	 * Gets item (data) by its key from local storage
	 * 
	 * @param {string} key The item key
	 * @returns {Promise<string>} The item
	 */
	static async getItem (key: string) {
		try {
			const item = await AsyncStorage.getItem(key);
			return item || "";
		} catch (error) {
			console.error(error);
			return "";
		}
	}

	/**
	 * Store a value into the local storage
	 * 
	 * @param {string} key The item key
	 * @param {string} value The data to store
	 */
	static async setItem (key: string, value: string) {
		try {
			await AsyncStorage.setItem(key, value);
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Gets the player name from the local storage
	 * 
	 * @returns {string} Returns the player name
	 */
	static async getPlayerName () {
		return await this.getItem("playerName");
	}

	/**
	 * Store the player name into the local storage
	 * 
	 * @param {string} playerName The player name
	 */
	static setPlayerName (playerName: string) {
		this.setItem("playerName", playerName);
	}

	/**
	 * Gets from the local storage, the value indicating whether the sound is enabled
	 * 
	 * @returns {Promise<boolean>} The value
	 */
	static async getEnableSound () {
		const item = await this.getItem("enablingSound");
		return item ? item === "true" : true;
	}

	/**
	 * Set into the local storage, the value indicating whether the sound is enabled
	 * 
	 * @param {boolean} enablingSound The new value
	 */
	static setEnableSound (enablingSound: boolean) {
		this.setItem("enablingSound", enablingSound.toString());
	}

	/**
	 * Gets from the local storage, the value indicating whether the phone vibration is enabled
	 * 
	 * @returns {Promise<boolean>} The value
	 */
	static async getEnableVibration () {
		const item = await this.getItem("enablingVibration");
		return item ? item === "true" : true;
	}

	/**
	 * Set into the local storage, the value indicating whether the phone vibration is enabled
	 * 
	 * @param {boolean} enablingVibration The new value
	 */
	static setEnableVibration (enablingVibration: boolean) {
		this.setItem("enablingVibration", enablingVibration.toString());
	}

	/**
	 * Get saved custom challenges
	 * 
	 * @returns {CustomChallenge[]} A custom challenge list
	 */
	static async getSavedCustomChallenges () {
		const itemsStr = await this.getItem("cchallenges");
		const items: CustomChallenge[] = JSON.parse(itemsStr.length > 0 ? itemsStr : "[]");
		return items ?? [];
	}

	/**
	 * Set into the local storage, the custom challenge list
	 * 
	 * @param {CustomChallenge[]} toSave Challenges to save
	 * @param {CustomChallenge[]} toRemove Challenges to remove
	 */
	static async setSavedCustomChallenges (toSave: CustomChallenge[], toRemove: CustomChallenge[]) {
		const inStore = await this.getSavedCustomChallenges();

		const newValue = inStore.filter(c => !toRemove.some(r => r.description === c.description));
		newValue.unshift(...toSave.filter(c => !inStore.some(item => item.description === c.description)));

		await this.setItem("cchallenges", JSON.stringify(newValue));
	}

}