import React from "react";
import { NavigationContainer, DefaultTheme, Theme } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useToken } from "native-base";
import HomeScreen from "screens/Home/HomeScreen";
import RulesScreen from "screens/Rules/RulesScreen";
import ProposeChallengeScreen from "screens/ProposeChallenge/ProposeChallengeScreen";
import NewGameScreen from "screens/NewGame/NewGameScreen";
import PartyScreen from "screens/Party/PartyScreen";
import EndGameScreen from "screens/EndGame/EndGameScreen";
import SettingsScreen from "screens/Settings/SettingsScreen";

export type StackParamList = {
	Home: undefined,
	NewGame: undefined,
	Party: undefined,
	EndGame: undefined,
	Rules: undefined,
	ProposeChallenge: undefined,
	Settings: undefined,
}

const Stack = createNativeStackNavigator<StackParamList>();

function Navigator () {
	const [contentBgColor] = useToken("colors", ["bg.700"]);

	const navTheme: Theme = {
		...DefaultTheme,
		colors: {
			...DefaultTheme.colors,
			background: contentBgColor,
		}
	};

	return (
		<NavigationContainer theme={navTheme}>
			<Stack.Navigator
				screenOptions={{
					title: "Soirée Défis",
					orientation: "portrait",
					headerShown: false
				}}
			>
				<Stack.Screen name="Home" component={HomeScreen} />
				<Stack.Screen name="NewGame" component={NewGameScreen} />
				<Stack.Screen name="Party" component={PartyScreen} />
				<Stack.Screen name="EndGame" component={EndGameScreen} />
				<Stack.Screen name="Rules" component={RulesScreen} />
				<Stack.Screen name="Settings" component={SettingsScreen} />
				<Stack.Screen name="ProposeChallenge" component={ProposeChallengeScreen} />
			</Stack.Navigator>
		</NavigationContainer>
	);
}

export default Navigator;