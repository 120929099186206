import React, { useState, useEffect } from "react";
import { Button, HStack, Text, VStack } from "native-base";
import { CustomChallenge } from "models/Challenge";
import CChallengeItem from "./CChallengeItem";
import LocalStorage from "services/LocalStorage";
import PopupContent from "components/Popup/PopupContent";
import TextSeparator from "components/TextSeparator/TextSeparator";

interface IProps {
	onImport: (items: CustomChallenge[]) => void;
	onCancel: () => void;
}

function SavedCustomChallenges ({ onImport, onCancel }: IProps) {
	const [challenges, setChallenges] = useState<CustomChallenge[]>([]);
	const [chlgesToImport, setChlgesToImport] = useState<CustomChallenge[]>([]);

	useEffect(() => {
		LocalStorage.getSavedCustomChallenges().then(setChallenges);
	}, []);

	const handleToggleItemImport = (item: CustomChallenge, selected: boolean) => {
		if (selected) {
			setChlgesToImport(value => [item, ...value]);
		} else {
			setChlgesToImport(value => value.filter(c => c.description !== item.description));
		}
	};

	const handleValidateImportation = () => {
		onImport(chlgesToImport);
	};

	const handleRemoveItem = (item: CustomChallenge) => {
		LocalStorage.setSavedCustomChallenges([], [item]);
		setChlgesToImport(value => value.filter(c => c.description !== item.description));
		setChallenges(value => value.filter(c => c.description !== item.description));
	};

	return (
		<PopupContent
			title="Vos défis favoris"
			topButton={
				<TextSeparator text="Importer vos défis" />
			}
			bottomButton={
				<HStack justifyContent="space-evenly">
					<Button onPress={onCancel}>Retour</Button>
					<Button
						disabled={chlgesToImport.length === 0}
						onPress={handleValidateImportation}
					>
					Importer
					</Button>
				</HStack>
			}
		>
			<VStack space={4}>
				{
					challenges.map((item, inx) => (
						<CChallengeItem
							key={inx}
							item={item}
							selected={chlgesToImport.some(c => c.description === item.description)}
							onSelected={handleToggleItemImport}
							onItemRemove={handleRemoveItem}
						/>
					))
				}
				{
					challenges.length === 0 && (
						<Text color="gray.300" textAlign="center">Aucun défi.</Text>
					)
				}
			</VStack>
		</PopupContent>
	);
}

export default SavedCustomChallenges;