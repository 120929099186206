import React from "react";
import Header from "components/Header/Header";
import Content from "components/Content/Content";
import { Button } from "native-base";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StackParamList } from "components/Navigator/Navigator";
import Screen from "components/Screen/Screen";

function HomeScreen () {
	const navigation = useNavigation<NavigationProp<StackParamList>>();

	const gotoNewGame = () => navigation.navigate("NewGame");
	const gotoRules = () => navigation.navigate("Rules");
	const gotoProposeChallenge = () => navigation.navigate("ProposeChallenge");
	const gotoSettings = () => navigation.navigate("Settings");

	return (
		<Screen>
			<Header />
			<Content title="Bienvenue !">
				<Button onPress={gotoNewGame}>Jouer</Button>
				<Button onPress={gotoRules}>Règles</Button>
				<Button onPress={gotoProposeChallenge}>Proposer un défi</Button>
				<Button onPress={gotoSettings}>Paramètres</Button>
			</Content>
		</Screen>
	);
}

export default HomeScreen;