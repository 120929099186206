import React from "react";
import { Divider, HStack, Icon, IconButton, Text, View, VStack } from "native-base";
import { FontAwesome, MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import Player from "models/Player";

interface IProps {
	players: Player[];
	isMaster: boolean;
	onExcludePlayer: (playerName: string) => void;
}

function PlayersList ({ players, isMaster, onExcludePlayer }: IProps) {
	return (
		<VStack borderRadius={5} overflow="hidden">
			<View bgColor="bg.500" p={4}>
				<Text fontSize="xl" textAlign="center" bold>
					{players.filter(p => p.connected).length} Joueurs :
				</Text>
			</View>
			<VStack>
				{
					players.map((item, inx) => (
						<VStack key={item.name}>
							{inx !== 0 && <Divider />}
							<HStack
								position="relative"
								justifyContent={"center"}
								alignItems="center"
								bgColor="bg.200"
								p={2}
							>
								{item.isReadyToStart && item.connected && (
									<View position="absolute" left={2}>
										<Text><FontAwesome name="check-circle" size={25} /></Text>
									</View>
								)}

								{!item.connected && (
									<View position="absolute" left={2}>
										<Text color="red.500"><AntDesign name="closecircle" size={24} /></Text>
									</View>
								)}

								<Text color={item.connected ? "text.50" : "text.600"} fontSize="xl" bold>
									{item.name}
								</Text>

								{item.isRoomMaster && (
									<Icon
										position="absolute"
										right={3}
										as={MaterialCommunityIcons}
										size="lg"
										name="crown"
										color="text.50"
									/>
								)}

								{isMaster && !item.isRoomMaster && (
									<View position="absolute" right={0}>
										<IconButton
											icon={
												<Icon
													as={FontAwesome}
													size="lg"
													name="remove"
													color="text.50"
												/>
											}
											onPress={() => onExcludePlayer(item.name)}
										/>
									</View>
								)}
							</HStack>
						</VStack>
					))
				}
			</VStack>
		</VStack>
	);
}

export default PlayersList;