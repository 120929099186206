import React from "react";
import { VStack } from "native-base";

interface IProps {
	children: React.ReactNode
}

function RemarksGroup ({ children }: IProps) {
	return (
		<VStack space={5}>
			{children}
		</VStack>
	);
}

export default RemarksGroup;