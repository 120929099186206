/**
 * Defines all socket messages of the application
 */
export enum SocketMessage {
	CONNECT = "connect",
	CONNECT_ERROR = "connect_error",
	DISCONNECTING = "disconnecting",
	CREATE_ROOM = "createRoom",
	JOIN_ROOM = "joinRoom",
	ROOM_JOINED = "roomJoined",
	EXPULSE_PLAYER = "expulsePlayer",
	EXCLUDED_FROM_ROOM = "excludedFromRoom",
	LEAVE_PARTY = "leaveParty",
	START_PARTY = "startParty",
	SEND_CUSTOM_CHALLENGES = "sendCustomChallenges",
	UPDATE_PARTY = "updateParty",
	COMPLETE_CHALLENGE = "completeChallenge",
	CHALLENGE_COMPLETED = "challengeCompleted",
	FINISH_PARTY = "finishParty",
	GAME_FINISHED = "gameFinished",
	ALERT = "alert",
	POPUP_ALERT = "popupAlert"
}