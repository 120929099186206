import React, { useState, useEffect } from "react";
import { Button, Divider, HStack, Icon, IconButton, Text, VStack } from "native-base";
import Content from "components/Content/Content";
import Header from "components/Header/Header";
import Screen from "components/Screen/Screen";
import { useParty } from "components/providers/PartyProvider";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StackParamList } from "components/Navigator/Navigator";
import { GameStatus } from "models/Room";
import * as Clipboard from "expo-clipboard";
import { useMyToast } from "hooks/MyToast";
import { useAlertPopup } from "components/providers/AlertPopupProvider";
import SocketEmitter from "services/socket/SocketEmitter";
import SocketReceiver from "services/socket/SocketReceiver";
import { SimpleLineIcons } from "@expo/vector-icons";
import PlayersList from "components/PlayersList/PlayersList";
import Popup from "components/Popup/Popup";
import PopupContent from "components/Popup/PopupContent";
import ChallengeDoneResult from "components/ChallengeDoneResult/ChallengeDoneResult";
import { AlertType } from "models/sockets/AlertData";
import CustomChallengeWrapper from "components/CustomChallenge/CustomChallengeWrapper";
import TimerWidget from "components/Content/TimerWidget";

function PartyScreen () {
	const [openMenu, setOpenMenu] = useState(false);
	const [showCustomChallenges, setShowCustomChallenges] = useState(false);

	const { party, clearParty } = useParty();
	const navigation = useNavigation<NavigationProp<StackParamList>>();
	const toast = useMyToast();
	const alertPopup = useAlertPopup();

	useEffect(() => {
		const unsubOnExpulsed = SocketReceiver.onExludedFromRoom(onExludedFromRoom);

		return () => {
			unsubOnExpulsed();
		};
	}, []);

	useEffect(() => {
		if (!party?.room) {
			navigation.navigate("Home");
		}

		if (party?.room?.status === GameStatus.Finished) {
			navigation.navigate("EndGame");
		}
	}, [party, navigation]);

	const onExludedFromRoom = () => {
		alertPopup.open("Tu as été exclu de la soirée !", "L'hôte de la soirée t'as exclu de la partie.", undefined, true, 0);
	};

	const handleOpenMenu = () => {
		setOpenMenu(true);
	};

	const handleCloseMenu = () => {
		setOpenMenu(false);
	};

	const handleOpenCustomChallenges = () => {
		setShowCustomChallenges(true);
	};

	const handleCloseCustomChallenges = () => {
		setShowCustomChallenges(false);
	};

	const handleCopyRoomCode = () => {
		if (party?.room) {
			Clipboard.setStringAsync(party.room.roomCode);
			toast.show(AlertType.Success, "Le code a été copié dans le presse-papier");
		}
	};

	const handleExpulsePlayer = (player: string) => {
		setOpenMenu(false);
		alertPopup.open(`Expulser ${player} ?`, `Voulez-vous vraiment expulser ${player} ? Ses points seront perdus.`, () => {
			SocketEmitter.expulsePlayer(player);
		}, undefined, 0);
	};

	const handleLeaveParty = () => {
		setOpenMenu(false);
		alertPopup.open("Quitter la partie ?", "Voulez-vous vraiment quitter la partie ?", () => {
			SocketEmitter.leaveParty();
			clearParty();
		}, undefined, 0);
	};

	const handleStartParty = () => {
		SocketEmitter.startParty();
	};

	const handleFinishParty = () => {
		setOpenMenu(false);
		alertPopup.open(
			"Fin de la soirée ?",
			"Voulez-vous vraiment terminer la soirée ?",
			SocketEmitter.finishParty,
			undefined,
			0
		);
	};

	const handleCompleteChallenge = (success: boolean) => {
		if (party?.localPlayer?.challenges?.length) {
			const challenge = party?.localPlayer.challenges[0];
			SocketEmitter.completeChallenge(party?.room?.roomCode, challenge._id, success);
		}
	};

	const getHeader = () => {
		if (!party || !party.room || !party.localPlayer) {
			return (<Text textAlign="center" fontSize="xl" bold>Erreur</Text>);
		}

		return (
			<>
				<VStack>
					<Text textAlign="center" fontSize="4xl" bold>{party.localPlayer.points}</Text>
					<Divider />
					<Text fontSize="xl" bold>Points</Text>
				</VStack>
				<Text textAlign="center" fontSize="xl" bold>{party.localPlayer.name}</Text>
				<IconButton
					icon={
						<Icon
							as={SimpleLineIcons}
							size="4xl"
							name="menu"
							color="text.50"
						/>
					}
					onPress={handleOpenMenu}
				/>
			</>
		);
	};

	const getLobbyButton = () => {
		// -1 = custom challenge
		if (party?.room?.categoryLevel === -1 && party.localPlayer?.isReadyToStart === false) {
			return [
				<Button key="1" onPress={handleOpenCustomChallenges}>Proposer des défis</Button>
			];
		}

		if (party?.localPlayer?.isRoomMaster) {
			return [
				<Button colorScheme="green" key={1} onPress={handleStartParty}>Démarrer</Button>
			];
		}
	};

	const getContent = () => {
		const display = party?.display;
		const widgets: React.ReactNode[] = [];

		switch (party?.room?.status) {
			case GameStatus.WaitingForPlayer:
			case GameStatus.ReadyToStart:
				return (
					<Content title="En attente de joueurs..."
						buttons={getLobbyButton()}
					>
						{
							party?.localPlayer?.isRoomMaster && (
								<Text textAlign="center" fontSize="xl">
                  Partagez le code
									<Text color="red.200" bold onPress={handleCopyRoomCode}> {party?.room?.roomCode} </Text>
                  avec vos amis pour commencer la soirée !
									{"\n"}
								</Text>
							)
						}
						<PlayersList
							players={party?.room.players}
							isMaster={party?.localPlayer?.isRoomMaster || false}
							onExcludePlayer={handleExpulsePlayer}
						/>
					</Content>
				);

			case GameStatus.Playing:
				if (display?.timer) {
					widgets.push(<TimerWidget id={display.text} timeMs={display.timer * 60000} key="timerWidget" />);
				}

				return (
					<Content title={display?.title ? display.title : "Défi"}
						widgets={widgets}
						buttons={
							!display?.hideButtons ? [
								<Button colorScheme="green" key={1} onPress={() => handleCompleteChallenge(true)}>{"J'ai réussi"}</Button>,
								<Button colorScheme="red" key={2} onPress={() => handleCompleteChallenge(false)}>{"J'ai échoué"}</Button>,
							] : []
						}
					>
						<Text fontSize="xl" bold>
							{display?.text ? display.text : "Une erreur est survenue !"}
						</Text>
					</Content>
				);

			default:
				return <Text>Game is running</Text>;
		}
	};

	return (
		<Screen disableBackButton>
			<Header>{getHeader()}</Header>
			{getContent()}
			<Popup isOpen={openMenu} zIndex={1}>
				<PopupContent title="Menu" onHide={handleCloseMenu}>
					<HStack justifyContent="space-around" mb={5}>
						{
							party?.localPlayer?.isRoomMaster && party?.room?.status === GameStatus.Playing && (
								<Button colorScheme="red" onPress={handleFinishParty}>Terminer</Button>
							)
						}
						<Button colorScheme="red" onPress={handleLeaveParty}>Quitter</Button>
					</HStack>
					<PlayersList
						players={party?.room ? party?.room.players : []}
						isMaster={party?.localPlayer?.isRoomMaster || false}
						onExcludePlayer={handleExpulsePlayer}
					/>
				</PopupContent>
			</Popup>
			<ChallengeDoneResult />
			{
				(party?.localPlayer?.isReadyToStart === false)
				&& party?.room?.categoryLevel === -1
				&& <CustomChallengeWrapper isOpen={showCustomChallenges} onSent={handleCloseCustomChallenges} />
			}
		</Screen>
	);
}

export default PartyScreen;