import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import Party from "models/Party";
import SocketReceiver from "services/socket/SocketReceiver";
import { PartUpdatedData, PartyFinishedData, RoomJoinedData } from "models/sockets/PartyData";
import SoundPlayer from "services/SoundPlayer";

interface Props {
	children: React.ReactNode
}

interface PartyContextType {
	party: Party,
	clearParty: () => void
}

export const PartyContext = createContext<PartyContextType>({} as PartyContextType);

function PartyProvider ({ children }: Props) {
	const [party, setParty] = useState<Party>();
	const playSoundNextUpdateRef = useRef<boolean>(false);

	useEffect(() => {
		const unsubRoomJoined = SocketReceiver.onRoomJoined(onRoomJoined);
		const unsubPartyUpdate = SocketReceiver.onPartyUpdate(onPartyUpdate);
		const unsubPartyFinished = SocketReceiver.onPartyFinished(onPartyFinished);
		const unsubExludedFromRoom = SocketReceiver.onExludedFromRoom(onExludedFromRoom);

		return () => {
			unsubRoomJoined();
			unsubPartyUpdate();
			unsubPartyFinished();
			unsubExludedFromRoom();
		};
	}, []);

	const onRoomJoined = (data: RoomJoinedData) => {
		setParty(value => ({
			...value,
			...data
		}));
	};

	const onPartyUpdate = ({ room, localPlayer, display, isWaitingForPartener }: PartUpdatedData) => {
		setParty(value => {
			const newValue = {
				...value,
				localPlayer,
				room
			};

			if (display) {
				newValue.display = display;
			}

			return newValue;
		});

		if (playSoundNextUpdateRef.current) {
			SoundPlayer.playAlertSound();
		}

		playSoundNextUpdateRef.current = isWaitingForPartener;
	};

	const onPartyFinished = (data: PartyFinishedData) => {
		setParty(value => ({
			...value,
			...data
		}));
	};

	const onExludedFromRoom = () => {
		setParty(undefined);
	};

	const clearParty = () => {
		setParty(undefined);
	};

	const value: PartyContextType = {
		party: party as Party,
		clearParty
	};

	return (
		<PartyContext.Provider value={value}>
			{children}
		</PartyContext.Provider>
	);
}

export function useParty () {
	return useContext(PartyContext);
}

export default PartyProvider;