import React, { createContext, useContext, useState, useEffect, useImperativeHandle } from "react";
import { Button, HStack, Text } from "native-base";
import Popup from "components/Popup/Popup";
import PopupContent from "components/Popup/PopupContent";
import SoundPlayer from "services/SoundPlayer";
import SocketReceiver from "services/socket/SocketReceiver";
import { AlertData, PopupAlertData } from "models/sockets/AlertData";
import { useMyToast } from "hooks/MyToast";

interface IProps {
	children: React.ReactNode,
	myRef: React.Ref<IAlertPopupContext>
}

interface IAlertPopupContext {
	open: (title: string, message: string, onConfirm?: () => void, playSound?: boolean, duration?: number) => void
}

const AlertPopupContext = createContext<IAlertPopupContext>({} as IAlertPopupContext);
export const AlertPopupRef = React.createRef<IAlertPopupContext>();

function AlertPopupProvider ({ children, myRef }: IProps) {
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState("");
	const [onConfirm, setOnConfirm] = useState<() => void>();
	const [duration, setDuration] = useState(5000);
	const [isOpen, setIsOpen] = useState(false);

	const toast = useMyToast();

	useImperativeHandle(myRef, () => ({
		open
	}), []);

	useEffect(() => {
		const unsubOnPopupAlert = SocketReceiver.onPopupAlert(onPopupAlert);
		const unsubOnToastAlert = SocketReceiver.onToastAlert(onToastAlert);

		return () => {
			unsubOnPopupAlert();
			unsubOnToastAlert();
		};
	}, []);

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (isOpen && duration > 0) {
			timeout = setTimeout(handleHide, duration);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [isOpen, duration]);

	const onPopupAlert = (data: PopupAlertData) => {
		open(data.title, data.message, undefined, true, 15000);
	};

	const onToastAlert = (data: AlertData) => {
		toast.show(data.type, data.message);
	};

	const open = (title: string, message: string, onConfirm?: () => void, playSound = false, duration = 5000) => {
		setTitle(title);
		setMessage(message);
		setOnConfirm(() => onConfirm);
		setIsOpen(true);
		setDuration(duration);
		if (playSound) {
			SoundPlayer.playAlertSound();
		}
	};

	const handleHide = () => {
		setIsOpen(false);
	};

	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}
		handleHide();
	};

	const providerValue: IAlertPopupContext = {
		open: open
	};

	return (
		<AlertPopupContext.Provider value={providerValue}>
			{children}
			<Popup isOpen={isOpen}>
				<PopupContent title={title} onHide={handleHide} showButton={!onConfirm}>
					<Text textAlign="center">{message}</Text>
					{
						onConfirm && (
							<HStack justifyContent="space-between" mt={5}>
								<Button colorScheme="red" onPress={handleHide}>Annuler</Button>
								<Button colorScheme="green" onPress={handleConfirm}>Confirmer</Button>
							</HStack>
						)
					}
				</PopupContent>
			</Popup>
		</AlertPopupContext.Provider>
	);
}

export function useAlertPopup () {
	return useContext(AlertPopupContext);
}

export default AlertPopupProvider;