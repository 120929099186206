import { AlertPopupRef } from "components/providers/AlertPopupProvider";
import { SocketError } from "models/sockets/SocketIoMessages";
import SocketReceiver from "./SocketReceiver";
import * as Linking from "expo-linking";

/**
 * Defines global socket events
 */
export class GlobalSocketEvents {
	/**
	 * Initialize global events
	 */
	public static init () {
		SocketReceiver.onConnectError(GlobalSocketEvents.onConnectError);
	}

	/**
	 * Handled when an socket connection occured
	 *  
	 * @param {SocketError} error The error
	 */
	private static onConnectError (error: SocketError) {
		if (error.message === "VersionError") {
			setTimeout(() => {
				AlertPopupRef.current?.open(
					"Mise à jour disponible !",
					"Tu dois mettre à jour l'application pour jouer ! Veux-tu le faire maintenant ?",
					() => {
						Linking.openURL("market://details?id=com.soireedefis");
					},
					false,
					0
				);
			}, 500);
		}
	}
}