import React, { useState } from "react";
import { Text } from "native-base";
import Screen from "components/Screen/Screen";
import Content from "components/Content/Content";
import Header from "components/Header/Header";
import { ProposalService } from "services/api/app/ProposalService";
import { Button, Spinner, useDisclose, VStack } from "native-base";
import { useChallengeEditor } from "hooks/ChallengeEditor";
import Popup from "components/Popup/Popup";
import PopupContent from "components/Popup/PopupContent";
import Recaptcha from "components/Recaptcha/Recaptcha";
import WithRecaptchaProvider from "components/Recaptcha/WithRecaptchaProvider";

function ProposeChallengeScreen () {
	const { isOpen: openRemark, onToggle: toggleRemarkPopup } = useDisclose(false);
	const { isOpen: openSuccessPopup, onToggle: toggleSuccessPopup } = useDisclose(false);

	const [isLoading, setIsLoading] = useState(false);
	const [result, setResult] = useState<{ success?: boolean, errors?: string[] }>();

	const handleSendProposal = async () => {
		setIsLoading(true);
	};

	const sendProposalWithCaptcha = (token: string) => {
		const { description, type, categories, email } = editor.data;
		ProposalService.sendProposal(description, type, categories, email, token)
			.then(res => {
				setResult(res);
				toggleSuccessPopup();
				editor.action.gotoFirstStep();
				setIsLoading(false);
				if (res?.success === true) {
					editor.action.reset();
				}
			})
			.catch(() => {
				editor.action.gotoFirstStep();
				setIsLoading(false);
				setResult({ success: false });
				toggleSuccessPopup();
			});
	};

	const editor = useChallengeEditor(handleSendProposal);

	return (
		<WithRecaptchaProvider>
			<Screen>
				<Header showBackButton />
				<Content
					title="Proposer un challenge"
					buttons={!isLoading ? editor.buttons : []}
				>
					{
						!isLoading ? (
							<>
								{editor.step.input}
								<Button my={3} onPress={toggleRemarkPopup}>Remarques</Button>
							</>) : (<Spinner />)
					}
				</Content>
				<Popup isOpen={openRemark}>
					<PopupContent title="Remarques" onHide={toggleRemarkPopup}>
						{editor.step.remark}
					</PopupContent>
				</Popup>
				<Popup isOpen={openSuccessPopup} onClose={toggleSuccessPopup}>
					<PopupContent title={result?.success === true ? "Merci beaucoup !" : "Oups..."} onHide={toggleSuccessPopup}>
						{
							result?.success === true ?
									<Text>{"Votre défi a bien été enregistré ! Nous l'étudierons avant validation."}</Text> :
									<VStack space={2}>
										{
											result?.errors && result.errors.length > 0 ?
												result?.errors.map((e, i) => <Text key={i}>{`${"\u2022"} ${e}`}</Text>) :
													<Text>{"\u2022"} Une erreur est survenue, veuillez réessayer.</Text>
										}
									</VStack>
						}
					</PopupContent>
				</Popup>
				{
					isLoading && <Recaptcha onReceiveToken={sendProposalWithCaptcha} />
				}
			</Screen>
		</WithRecaptchaProvider>
	);

}

export default ProposeChallengeScreen;