import React from "react";
import { Text, View, HStack } from "native-base";
import { IFontSize } from "native-base/lib/typescript/theme/base/typography";

interface IProps {
	text: string;
	fontSize?: IFontSize;
	my?: number;
}

function TextSeparator ({ text, fontSize = "xl", my }: IProps) {
	const line = <View flex={1} h={0.5} bgColor="text.50" />;
	return (
		<HStack w="full" my={my} alignItems="center" space={1}>
			{line}
			<Text fontSize={fontSize} bold>{text}</Text>
			{line}
		</HStack>
	);
}

export default TextSeparator;