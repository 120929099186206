import React, { useState, useEffect } from "react";
import { Platform } from "react-native";
import Header from "components/Header/Header";
import Screen from "components/Screen/Screen";
import Content from "components/Content/Content";
import LocalStorage from "services/LocalStorage";
import { VStack } from "native-base";
import Checkbox from "components/Inputs/Checkbox";

function SettingsScreen () {
	const [enableSound, setEnableSound] = useState(true);
	const [enableVibration, setEnableVibration] = useState(true);

	useEffect(() => {
		LocalStorage.getEnableSound().then(setEnableSound);
		LocalStorage.getEnableVibration().then(setEnableVibration);
	}, []);

	const handleEnableSoundChange = (value: boolean) => {
		setEnableSound(value);
		LocalStorage.setEnableSound(value);
	};

	const handleEnableVibrationChange = (value: boolean) => {
		setEnableVibration(value);
		LocalStorage.setEnableVibration(value);
	};

	return (
		<Screen>
			<Header showBackButton />
			<Content title="Paramètres">
				<VStack>
					<Checkbox label="Activer le son" checked={enableSound} onChange={handleEnableSoundChange} />
					{
						Platform.OS !== "web" && (
							<Checkbox label="Activer les vibrations" checked={enableVibration} onChange={handleEnableVibrationChange} />
						)
					}
				</VStack>
			</Content>
		</Screen>
	);
}

export default SettingsScreen;