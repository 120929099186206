import Player from "./Player";

type Room = {
	roomCode: string;
	status: GameStatus;
	players: Player[];
	categoryLevel: number;
}

export enum GameStatus {
	WaitingForPlayer = "WaitingForPlayer",
	ReadyToStart = "ReadyToStart",
	Playing = "Playing",
	Finished = "Finished",
}

export default Room;