import React, { useState, useEffect } from "react";
import Popup from "components/Popup/Popup";
import { Text, useDisclose } from "native-base";
import PopupContent from "components/Popup/PopupContent";
import SocketReceiver from "services/socket/SocketReceiver";
import { ChallengeCompletedData } from "models/sockets/ChallengeData";

function ChallengeDoneResult () {
	const [success, setSuccess] = useState(false);
	const [points, setPoints] = useState(0);

	const { isOpen, onOpen, onClose } = useDisclose(false);

	useEffect(() => {
		const unsubOnChlgeCompleted = SocketReceiver.onChallengeCompleted(onChallengeCompleted);

		return () => {
			unsubOnChlgeCompleted();
		};
	}, []);

	const onChallengeCompleted = (data: ChallengeCompletedData) => {
		setSuccess(data.success);
		setPoints(data.points);
		onOpen();
	};

	return (
		<Popup isOpen={isOpen}>
			<PopupContent title={`Défi ${success ? "réussi !" : "échoué !"}`} onHide={onClose}>
				<Text fontSize="xl" textAlign="center">
					{success ? "Tu as gagné " : "Tu as perdu "}
					<Text color={success ? "green.300" : "red.300"} bold>
						{success && "+"}{points} point.s
					</Text>
				</Text>
			</PopupContent>
		</Popup>
	);
}

export default ChallengeDoneResult;