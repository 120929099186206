import React from "react";
import { TouchableOpacity } from "react-native";
import { HStack, Text } from "native-base";
import { ColorType, ResponsiveValue } from "native-base/lib/typescript/components/types";
import { ILinearGradientProps } from "native-base/lib/typescript/components/primitives/Box/types";

type Props = {
	checked?: boolean;
	label: string;
	checkedBgColor?: ResponsiveValue<ColorType | ILinearGradientProps>;
	onChange: (value: boolean) => void;
}

function Checkbox ({ checked, label, onChange, checkedBgColor = "bg.600" }: Props) {
	const handleChange = () => {
		onChange(!checked);
	};

	return (
		<TouchableOpacity style={{ margin: 10 }} onPress={handleChange}>
			<HStack alignItems="center" space={2}>
				<HStack
					justifyContent="center"
					alignItems="center"
					w={30}
					h={30}
					borderRadius={5}
					bgColor={checked ? checkedBgColor : undefined}
					style={!checked ? {backgroundColor: "rgba(255,255,255,0.5)"} : []}>
					{checked && <Text bold>✓</Text>}
				</HStack>
				<Text>{label}</Text>
			</HStack>
		</TouchableOpacity>
	);
}

export default Checkbox;