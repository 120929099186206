import React, { useEffect } from "react";
import { BackHandler } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

interface IProps {
	children: React.ReactNode;
	disableBackButton?: boolean
}

function Screen ({ children, disableBackButton }: IProps) {
	useEffect(() => {
		if (disableBackButton) {
			const backHandler = BackHandler.addEventListener("hardwareBackPress", () => true);
			return () => backHandler.remove();
		}
	}, []);

	return (
		<SafeAreaView
			style={{
				display: "flex",
				alignItems: "center",
				flex: 1
			}}
		>
			{children}
		</SafeAreaView>
	);
}

export default Screen;