import React from "react";
import { Button, HStack, Icon, IconButton, Text, VStack } from "native-base";
import { CustomChallenge } from "models/Challenge";
import TextSeparator from "components/TextSeparator/TextSeparator";
import CChallengeItem from "./CChallengeItem";
import PopupContent from "components/Popup/PopupContent";
import { AntDesign, Ionicons, MaterialIcons } from "@expo/vector-icons";

interface IProps {
	challenges: CustomChallenge[];
	validateBtnText: string;
	isValidateBtnEnabled: boolean;
	showDuoChlgeError: boolean
	onItemRemove: (item: CustomChallenge) => void;
	onShowAddChallengeForm: () => void;
	onShowImportView: () => void;
	onSaveCustomChallenges: () => void;
	onShowLooserChallengeForm: () => void;
}

function CChallengeList (props: IProps) {
	const {
		challenges,
		validateBtnText,
		isValidateBtnEnabled,
		showDuoChlgeError,
		onItemRemove,
		onShowAddChallengeForm,
		onShowImportView,
		onSaveCustomChallenges,
		onShowLooserChallengeForm
	} = props;

	const getTopButton = () => {
		return (
			<VStack space={3}>
				<HStack space={4} justifyContent="center">
					<IconButton
						bgColor="info.600"
						borderRadius={50}
						icon={
							<Icon
								as={AntDesign}
								name="plus"
								color="text.50"
								size="xl"
							/>
						}
						onPress={onShowAddChallengeForm}
					/>
					<IconButton
						bgColor="info.600"
						borderRadius={50}
						icon={
							<Icon
								as={MaterialIcons}
								name="favorite"
								color="text.50"
								size="xl"
							/>
						}
						onPress={onShowImportView}
					/>
					<IconButton
						bgColor="info.600"
						borderRadius={50}
						icon={
							<Icon
								as={Ionicons}
								name="save-outline"
								color="text.50"
								size="xl"
							/>
						}
						onPress={onSaveCustomChallenges}
					/>
				</HStack>
				<VStack>
					<TextSeparator text="Vos défis" />
					{
						showDuoChlgeError && (
							<Text color="danger.500" bold>Un défi de type Team ou VS est requis !</Text>
						)
					}
				</VStack>
			</VStack>
		);
	};

	return (
		<PopupContent
			title="Ajouter vos défis"
			showButton={false}
			topButton={getTopButton()}
			bottomButton={
				<Button
					colorScheme="green"
					isDisabled={isValidateBtnEnabled}
					onPress={onShowLooserChallengeForm}
				>
					{validateBtnText}
				</Button>
			}
		>
			<VStack space={4}>
				{
					challenges.map((item, inx) => (
						<CChallengeItem key={inx} item={item} onItemRemove={onItemRemove} />
					))
				}
				{
					challenges.length === 0 && (
						<Text color="gray.300" textAlign="center">Aucun défi.</Text>
					)
				}
			</VStack>
		</PopupContent>
	);
}

export default CChallengeList;