import React, { useEffect, useCallback } from "react";
import { NativeBaseProvider, extendTheme } from "native-base";
import Navigator from "components/Navigator/Navigator";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import SocketService from "services/socket/SocketService";
import PartyProvider from "components/providers/PartyProvider";
import AlertPopupProvider, { AlertPopupRef } from "components/providers/AlertPopupProvider";
import ConnectionErrorHandler from "components/ErrorHandlers/ConnectionErrorHandler";

SplashScreen.preventAutoHideAsync();

const theme = extendTheme({
	colors: {
		text: {
			50: "#ffffff",
			600: "#DADADA",
			900: "#ffffff",
		},
		info: {
			600: "#008BFF"
		},
		bg: {
			200: "#72AFFF",
			300: "#3C8AFF",
			400: "#0971e8",
			500: "#2079FF",
			600: "#0752a8",
			700: "#051536"
		},
	},
	components: {
		HStack: {
			defaultProps: {
				alignItems: "center",
			}
		},
		Button: {
			defaultProps: {
				variant: "solid",
				colorScheme: "info",
				borderRadius: 50,
				borderWidth: 1,
				borderColor: "muted.100",
				_text: {
					fontSize: "lg",
					fontWeight: "bold"
				},
				_disabled: {
					opacity: 60
				},
			},
			variants: {
				unstyled: {
					style: {
						borderWidth: 0
					},
				}
			}
		},
		Text: {
			defaultProps: {
				fontSize: "lg"
			}
		},
		Input: {
			baseStyle: {
				backgroundColor: "rgba(255,255,255,0.2)",
			},
			defaultProps: {
				variant: "rounded",
				textAlign: "center",
				placeholderTextColor: "text.600",
				size: "xl",
				_focus: {
					borderColor: "text.50"
				}
			}
		},
		TextArea: {
			baseStyle: {
				backgroundColor: "rgba(255,255,255,0.2)",
			},
			defaultProps: {
				variant: "rounded",
				placeholderTextColor: "text.50",
				textAlign: "center",
				totalLines: 2,
				size: "xl",
				px: 5,
				_focus: {
					borderColor: "text.50",
				},
			}
		},
		Spinner: {
			defaultProps: {
				size: "lg",
				color: "text.50"
			}
		}
	}
});

type CustomThemeType = typeof theme;

declare module "native-base" {
	type ICustomTheme = CustomThemeType
}

export default function App () {
	useEffect(() => {
		SocketService.connect();
	}, []);

	const [fontsLoaded] = useFonts({
		"ComicSansMS": require("./src/assets/fonts/ComicSansMS.ttf"),
	});

	const onLayoutRootView = useCallback(async () => {
		if (fontsLoaded) {
			await SplashScreen.hideAsync();
		}
	}, [fontsLoaded]);

	if (!fontsLoaded) {
		return null;
	}

	return (
		<SafeAreaProvider onLayout={onLayoutRootView}>
			<NativeBaseProvider theme={theme}>
				<AlertPopupProvider myRef={AlertPopupRef}>
					<ConnectionErrorHandler />
					<PartyProvider>
						<Navigator />
					</PartyProvider>
				</AlertPopupProvider>
			</NativeBaseProvider>
		</SafeAreaProvider>
	);
}
