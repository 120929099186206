import SocketService from "./SocketService";
import { SocketMessage } from "models/SocketMessage";
import { AlertData, PopupAlertData } from "models/sockets/AlertData";
import { PartUpdatedData, PartyFinishedData, RoomJoinedData } from "models/sockets/PartyData";
import { ChallengeCompletedData } from "models/sockets/ChallengeData";
import { SocketError } from "models/sockets/SocketIoMessages";

/**
 * Defines socket events receivers
 */
export default class SocketReceiver {
	/** 
	 * Subscribes to CONNECT event message
	 * 
	 * @param {() => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onConnect (action: () => void) {
		SocketService.on(SocketMessage.CONNECT, action);

		return () => SocketService.off(SocketMessage.CONNECT, action);
	}

	/** 
	 * Subscribes to CONNECT_ERROR event message
	 * 
	 * @param {(error: SocketError) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onConnectError (action: (error: SocketError) => void) {
		SocketService.on(SocketMessage.CONNECT_ERROR, action);

		return () => SocketService.off(SocketMessage.CONNECT_ERROR, action);
	}

	/** 
	 * Subscribes to ALERT event message
	 * 
	 * @param {(alert: AlertData) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onToastAlert (action: (alert: AlertData) => void) {
		SocketService.on(SocketMessage.ALERT, action);

		return () => SocketService.off(SocketMessage.ALERT, action);
	}

	/** 
	 * Subscribes to POPUP_ALERT event message
	 * 
	 * @param {(alert: PopupAlertData) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onPopupAlert (action: (alert: PopupAlertData) => void) {
		SocketService.on(SocketMessage.POPUP_ALERT, action);

		return () => SocketService.off(SocketMessage.POPUP_ALERT, action);
	}

	/** 
	 * Subscribes to ROOM_JOINED event message
	 * 
	 * @param {(data: RoomJoinedData) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onRoomJoined (action: (data: RoomJoinedData) => void) {
		SocketService.on(SocketMessage.ROOM_JOINED, action);

		return () => SocketService.off(SocketMessage.ROOM_JOINED, action);
	}

	/** 
	 * Subscribes to EXCLUDED_FROM_ROOM event message
	 * 
	 * @param {() => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onExludedFromRoom (action: () => void) {
		SocketService.on(SocketMessage.EXCLUDED_FROM_ROOM, action);

		return () => SocketService.off(SocketMessage.EXCLUDED_FROM_ROOM, action);
	}

	/** 
	 * Subscribes to UPDATE_PARTY event message
	 * 
	 * @param {(party: PartUpdatedData) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onPartyUpdate (action: (party: PartUpdatedData) => void) {
		SocketService.on(SocketMessage.UPDATE_PARTY, action);

		return () => SocketService.off(SocketMessage.UPDATE_PARTY, action);
	}

	/** 
	 * Subscribes to CHALLENGE_COMPLETED event message
	 * 
	 * @param {(data: ChallengeCompletedData) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onChallengeCompleted (action: (data: ChallengeCompletedData) => void) {
		SocketService.on(SocketMessage.CHALLENGE_COMPLETED, action);

		return () => SocketService.off(SocketMessage.CHALLENGE_COMPLETED, action);
	}

	/** 
	 * Subscribes to GAME_FINISHED event message
	 * 
	 * @param {(data: PartyFinishedData) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onPartyFinished (action: (data: PartyFinishedData) => void) {
		SocketService.on(SocketMessage.GAME_FINISHED, action);

		return () => SocketService.off(SocketMessage.GAME_FINISHED, action);
	}
}