import React from "react";
import { Icon, View, IconButton } from "native-base";
import { FontAwesome } from "@expo/vector-icons";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StackParamList } from "../Navigator/Navigator";

function BackButton () {
	const navigation = useNavigation<NavigationProp<StackParamList>>();

	const handleClick = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else {
			navigation.navigate("Home");
		}
	};

	return (
		<View
			position="absolute"
			top={5}
			left={1}
			zIndex={1}
		>
			<IconButton
				borderRadius="full"
				icon={
					<Icon
						as={FontAwesome}
						name="arrow-left"
						size="4xl"
						color="text.50"
					/>
				}
				onPress={handleClick}
			/>
		</View>
	);
}

export default BackButton;