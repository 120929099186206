import React from "react";
import { View, Text } from "native-base";
import { ColorType } from "native-base/lib/typescript/components/types";

interface IProps {
	title: string;
	children: React.ReactNode;
	bgColor?: ColorType;
}

export function RemarkCard ({ title, children, bgColor = "bg.300" }: IProps) {
	return (
		<View borderRadius={10} bgColor={bgColor} overflow="hidden">
			<Text
				p={3}
				fontSize="xl"
				bold
				textAlign="center"
				style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
			>
				{title}
			</Text>
			<Text py={3} px={2} textAlign="center" fontSize="lg">
				{children}
			</Text>
		</View>
	);
}