import React, { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { IRecaptchaProps } from "./IRecaptchaProps";

function Recaptcha ({ onReceiveToken }: IRecaptchaProps) {
	const { executeRecaptcha } = useGoogleReCaptcha();

	useEffect(() => {
		if (executeRecaptcha) {
			executeRecaptcha("submit_challenge").then(onReceiveToken);
		}

	}, [executeRecaptcha]);

	return (
		<></>
	);
}

export default Recaptcha;