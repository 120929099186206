import Category from "./Category";

interface Challenge {
	_id: string;
	description: string;
	winPoints: number;
	loosePoints: number;
	type: ChallengeType;
	categories: Category[];
	completed?: boolean;
}

export type CustomChallenge = Pick<Challenge, "description" | "type">

export interface ProposableChallenge {
	propose: boolean;
	challenge: CustomChallenge
}

export enum ChallengeType {
	Solo = "Solo",
	VS = "VS",
	Thief = "Thief",
	Team = "Team",
}

export default Challenge;