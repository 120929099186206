import React, { useState, useEffect, useRef } from "react";
import { TouchableOpacity, AppStateStatus } from "react-native";
import { HStack, Text } from "native-base";
import { FontAwesome5 } from "@expo/vector-icons";
import SoundPlayer from "services/SoundPlayer";
import { useAppState } from "hooks/AppStateHook";

interface IProps {
	id: string;
	timeMs: number;
}

function TimerWidget ({ id, timeMs }: IProps) {
	const [started, setStarted] = useState(false);
	const [formatedTimeLeft, setFormatedTimeLeft] = useState("00:00");
	const [textColor, setTextColor] = useState("white");

	const timeLeftRef = useRef<number>(0);
	const timerRef = useRef<NodeJS.Timer>();

	useEffect(() => {
		timeLeftRef.current = timeMs;
		setStarted(false);
		setFormatedTimeLeft(new Date(timeMs).toTimeString().slice(3, 8));
		setTextColor("white");
		return () => {
			clearInterval(timerRef.current);
		};
	}, [id, timeMs]);

	const onAppStateChange = (state: AppStateStatus, prevState: AppStateStatus, appInactiveSpentTime: number) => {
		timeLeftRef.current -= appInactiveSpentTime;
	};

	useAppState({
		onAppStateChange
	});

	const handleStart = () => {
		if (!started) {
			timeLeftRef.current = timeMs;
			timerRef.current = setInterval(() => {
				timeLeftRef.current -= 1000;

				if (timeLeftRef.current < 0) {
					timeLeftRef.current = 0;
				}

				const percentLeft = timeLeftRef.current / timeMs;
				const hue = (percentLeft * 120).toString(10);
				setTextColor(["hsl(", hue, ",100%,50%)"].join(""));

				let text = new Date(timeLeftRef.current).toTimeString().slice(3, 8);

				if (timeLeftRef.current === 0) {
					SoundPlayer.playAlertSound();
					clearInterval(timerRef.current);
					text = "Terminé";
				}

				setFormatedTimeLeft(text);
			}, 1000);

			setStarted(true);
		}
	};

	return (
		<TouchableOpacity disabled={started} onPress={handleStart}>
			<HStack space={2} p={2} bgColor="light.900" borderRadius={20}>
				<Text style={{ color: textColor }}>
					<FontAwesome5 name="stopwatch" size={24} />
				</Text>
				<Text style={{ color: textColor }} bold>{formatedTimeLeft}</Text>
			</HStack>
		</TouchableOpacity>
	);
}

export default TimerWidget;