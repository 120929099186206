import React from "react";
import RemarksGroup from "components/Remark/RemarksGroup";
import { RemarkCard } from "components/Remark/RemarkCard";

function CategoriesExplanation () {
	return (
		<RemarksGroup>
			<RemarkCard title="Soft" bgColor="green.500">
				Défis fun et faciles à réaliser. Parfait si vous voulez rester sage ce soir.
			</RemarkCard>
			<RemarkCard title="Normal" bgColor="orange.400">
				Défis qui ambianceront votre soirée entre amis. Ni trop facile ni trop difficile.
			</RemarkCard>
			<RemarkCard title="Hard" bgColor="red.500">
				Défis plus difficiles et surtout plus osés. Sortez de votre zone de confort.
			</RemarkCard>
			<RemarkCard title="Personnalisé" bgColor="darkBlue.600">
				Créez et jouez avec vos propres défis !
				Chaque joueur propose un certain nombre de défis à réaliser pendant la soirée. Soyez inventif !
			</RemarkCard>
		</RemarksGroup>
	);
}

export default CategoriesExplanation;