import React, { useState, useEffect } from "react";
import { Button, Input, VStack } from "native-base";
import Content from "components/Content/Content";
import Header from "components/Header/Header";
import Screen from "components/Screen/Screen";
import TextSeparator from "components/TextSeparator/TextSeparator";
import SocketEmitter from "services/socket/SocketEmitter";
import LocalStorage from "services/LocalStorage";
import { useParty } from "components/providers/PartyProvider";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StackParamList } from "components/Navigator/Navigator";

function NewGameScreen () {
	const [playerName, setPlayerName] = useState("");
	const [roomCode, setRoomCode] = useState("");
	const [showCategory, setShowCategory] = useState(false);

	const { party } = useParty();
	const navigation = useNavigation<NavigationProp<StackParamList>>();

	useEffect(() => {
		LocalStorage.getPlayerName().then(name => {
			if (name) {
				setPlayerName(name);
			}
		});
	}, []);

	useEffect(() => {
		if (party) {
			navigation.navigate("Party");
		}
	}, [party, navigation]);

	const handleShowCategory = () => {
		setShowCategory(showCategory => !showCategory);
	};

	const handlePlayerNameChange = (value: string) => {
		setPlayerName(value);
		LocalStorage.setPlayerName(value);
	};

	const handleRoomCodeChange = (value: string) => {
		setRoomCode(value);
	};

	const handleCategoryLevelChange = (categoryLevel: number) => {
		handleCreateRoom(categoryLevel);
	};

	const handleCreateRoom = (categoryLevel: number) => {
		SocketEmitter.createRoom(playerName, categoryLevel);
	};

	const handleJoinRoom = () => {
		SocketEmitter.joinRoom(playerName, roomCode);
	};

	const mainForm = () => {
		return (
			<Content
				title="Nouvelle soirée !"
				buttons={[
					<Button
						key="1"
						isDisabled={playerName.length === 0}
						onPress={handleShowCategory}
					>
            Créer
					</Button>,
					<Button
						key="2"
						isDisabled={playerName.length === 0 || roomCode.length === 0}
						onPress={handleJoinRoom}
					>
            Rejoindre
					</Button>,
				]}
			>
				<Input
					placeholder="Votre prénom...*"
					value={playerName}
					onChangeText={handlePlayerNameChange}
				/>
				<TextSeparator text="Rejoindre une soirée" />
				<Input
					placeholder="Code de la soirée..."
					value={roomCode}
					autoCapitalize="characters"
					_web={{
						textTransform: roomCode.length > 0 ? "uppercase" : "none"
					}}
					onChangeText={handleRoomCodeChange}
				/>
			</Content>
		);
	};

	const categoryList = () => {
		return (
			<Content
				title="Catégories"
				buttons={[
					<Button key="1" onPress={handleShowCategory}>Retour</Button>
				]}
			>
				<VStack space={5}>
					<Button colorScheme="green" onPress={() => handleCategoryLevelChange(1)}>Soft</Button>
					<Button colorScheme="orange" onPress={() => handleCategoryLevelChange(2)}>Normal</Button>
					<Button colorScheme="red" onPress={() => handleCategoryLevelChange(3)}>Hard</Button>
					<Button colorScheme="darkBlue" onPress={() => handleCategoryLevelChange(-1)}>Personnalisé</Button>
				</VStack>
			</Content>
		);
	};

	return (
		<Screen>
			<Header showBackButton />
			{showCategory ? categoryList() : mainForm()}
		</Screen>
	);
}

export default NewGameScreen;