import { Button, ScrollView, Text, View, VStack } from "native-base";
import React from "react";

type Props = {
	title: string,
	children: React.ReactNode,
	onHide?: () => void,
	showButton?: boolean,
	topButton?: React.ReactElement
	bottomButton?: React.ReactElement
};

function PopupContent ({ title, children, onHide, topButton, bottomButton, showButton = true }: Props) {
	return (
		<VStack
			bgColor="bg.400"
			borderRadius={10}
			overflow="hidden"
			maxH="full">
			<View bgColor="bg.600" borderBottomRadius={50} p={2}>
				<Text fontSize="2xl" textAlign="center" bold>{title}</Text>
			</View>
			{
				topButton && (
					<View mt={5} px={2}>
						{topButton}
					</View>
				)
			}
			<ScrollView m={5}>
				{children}
			</ScrollView>
			<View mx={2} mb={3}>
				{showButton && !bottomButton && <Button onPress={onHide}>OK</Button>}
				{bottomButton}
			</View>
		</VStack>
	);
}

export default PopupContent;