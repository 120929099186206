import React from "react";
import { Platform } from "react-native";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "@env";

interface IProps {
	children: React.ReactElement;
}

function WithRecaptchaProvider ({ children }: IProps) {
	return (
		Platform.OS === "web" ? (
			<GoogleReCaptchaProvider
				reCaptchaKey={RECAPTCHA_SITE_KEY}
				language="fr"
			>
				{children}
			</GoogleReCaptchaProvider>
		) : (
			children
		)
	);
}

export default WithRecaptchaProvider;