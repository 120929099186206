import React from "react";
import { HStack, Icon, IconButton, Text, View, VStack } from "native-base";
import { FontAwesome } from "@expo/vector-icons";
import { CustomChallenge } from "models/Challenge";
import Checkbox from "components/Inputs/Checkbox";

interface IProps {
	item: CustomChallenge;
	selected?: boolean;
	onItemRemove: (item: CustomChallenge) => void;
	onSelected?: (item: CustomChallenge, checked: boolean) => void;
}

function CChallengeItem ({ item, selected, onItemRemove, onSelected }: IProps) {
	const handleRemove = () => {
		onItemRemove(item);
	};

	const handleSelectionChanged = (value: boolean) => {
		if (onSelected) {
			onSelected(item, value);
		}
	};

	return (
		<VStack borderRadius={5} style={{ backgroundColor: "rgba(255,255,255, 0.3)" }}>
			<HStack justifyContent="space-between" alignItems="stretch" borderTopRadius={5} overflow="hidden" style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
				<HStack py={1} px={5}>
					{onSelected && (
						<Checkbox
							label=""
							checkedBgColor="bg.300"
							checked={selected}
							onChange={handleSelectionChanged}
						/>
					)}
					<Text bold>{item.type}</Text>
				</HStack>
				<HStack bgColor="red.500">
					<IconButton
						icon={
							<Icon
								as={FontAwesome}
								size="lg"
								name="remove"
								color="text.50"
							/>
						}
						onPress={handleRemove}
					/>
				</HStack>
			</HStack>
			<View my={2} mx={2}>
				<Text textAlign="center" bold>{item.description}</Text>
			</View>
		</VStack>
	);
}

export default CChallengeItem;