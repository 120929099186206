import React, { ReactNode } from "react";
import {  ImageBackground } from "react-native";
import { Heading, HStack, Text, VStack } from "native-base";
import BackButton from "components/BackButton/BackButton";

type Props = {
	children?: ReactNode,
	showBackButton?: boolean,
}

function Header ({ children, showBackButton }: Props) {
	return (
		<ImageBackground
			resizeMode="cover"
			source={require("../../assets/headerbg.png")}
			style={{
				width: "100%",
				height: 160,
				zIndex: 10
			}}
		>
			{showBackButton && <BackButton />}
			<HStack justifyContent="space-around" alignItems="center" w="full" style={{height: "60%"}}>
				{
					children ? children : (
						<VStack alignItems="center">
							<Heading size="2xl" textTransform="uppercase">Soirée défis</Heading>
							<Text textTransform="uppercase" color="yellow.200" fontSize="lg" bold>Druolab</Text>
						</VStack>
					)
				}
			</HStack>
		</ImageBackground>
	);
}

export default Header;